/**
 * THEMES.LIGHT
 */



$base-bg:                       #fff;

$primary-color:                 #F8BB10;

$text-color:                    #939393;
$heading-color:                 darken($text-color, 13%);

$link-color:                    $primary-color;
$link-color--hover:             darken($link-color, 6.5%);

$base-border-color:             #ececec;

$brand-title-first-word-color:  $text-color;
$brand-title-second-word-color: $primary-color;
$brand-sub-title-color:         $text-color;

$icon-color:                    lighten($text-color, 30%);

$image-overlay-bg:              $base-bg;
$image-overlay-text-color:      darken($text-color, 35%);
$image-overlay-hover-opacity:   .2;
$image-overlay-deco-line:       darken($base-border-color, 60%);

$social-button-color:           lighten($text-color, 20%);
$social-button-bg:              darken($base-bg, 7%);
$social-button-color--hover:    $primary-color;
$social-button-bg--hover:       transparent;

$section-base-bg:               $base-bg;
$section-header-bg:             darken($section-base-bg, 1.4%);
$section-header-bg--darker:     darken($section-base-bg, 2%);
$section-content-bg:            $section-base-bg;
$section-header-bg--even:       darken($section-header-bg, 1.3%);
$section-content-bg--even:      darken($section-content-bg, 1.2%);



@import "themes.main";